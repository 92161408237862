import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1">
        &copy;{' '}
        <Link
          component="a"
          href="https://routecard.ovh/"
          target="_blank"
        >
          Routecard
        </Link>
        . 2025
      </Typography>
      <Typography variant="caption">
        Created with love for the environment. By designers and developers who
        love to be outside!<br />        
        This page uses code from:<br />
        Irish Grid System - fieldenmaps.info<br />
        Magnetic deviation - Bill Chadwick <br />
        Card grid, maps & elevation chart - google.com <br />
        Meteogram - highcharts.com <br />
        Time formatting - momentjs.com <br />
        Time picker - github.com/jdewit <br />
        Sun Times - github.com/mourner <br />
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
